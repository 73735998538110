<template>
  <div>
    <!-- Job List -->
    <vx-card>
      <div class="vx-row find-people" id="v-step-0">
        <!-- Intent leads table-->
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
          <div class="card-title btn-search w-full more-card-title flex flex-wrap pb-2 items-center">
            <div><h2 class="mb-0">Data Enrichment Jobs</h2></div>
            <!-- </div>
            <div class="flex btn-search w-full column-reverse flex-wrap justify-between"> -->
            <vs-button
              title="Add Job"
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              class="mr-2 float-left"
              @click="addDataenrichmentPopup = true"
            >{{ LabelConstant.buttonLabelAddJob }}</vs-button>
          </div>
          <div class="vx-row mb-3 data-enrichment-tbl">
            <div class="vx-col input-text w-full">
              <div class="vx-row">
                <div class="vx-col w-full mt-6">
                  <!-- table -->
                  <vs-table :data="jobList" search pagination>
                    <template slot="thead">
                      <vs-th></vs-th>
                      <vs-th sort-key="Status">{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                      <vs-th>{{ LabelConstant.tableHeaderLabelJobName }}</vs-th>
                      <vs-th>{{ LabelConstant.tableHeaderLabelJobType }}</vs-th>
                      <vs-th>{{ LabelConstant.tableHeaderLabelCreditsUsed }}</vs-th>
                      <vs-th>{{ LabelConstant.tableHeaderLabelContactsUploaded }}</vs-th>
                      <vs-th>{{ LabelConstant.tableHeaderLabelContactsFound }}</vs-th>
                      <vs-th>{{ LabelConstant.tableHeaderLabelCreatedBy }}</vs-th>
                      <vs-th sort-key="DateCreated">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td width="90">
                          <div class="table-action">
                            <vs-button
                              v-if="tr.Status == 'Completed' && tr.ReportDownloaded == false"
                              title="download"
                              color="primary"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-download"
                              class="float-left"
                              @click="getDataEnrichmentJobsDownload(tr)"
                            ></vs-button>
                            <!-- for downloaded gray btn  -->
                            <vs-button
                              v-if="tr.Status == 'Completed' && tr.ReportDownloaded == true"
                              title="downloaded"
                              color="gray"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-download"
                              class="float-left"
                              @click="getDataEnrichmentJobsDownload(tr)"
                            ></vs-button>
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].Status">
                          <span @click="tr.Status=='Completed'?getDataEnrichmentJobDetails(tr):showPopup(tr.Status)">{{ data[indextr].Status }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].JobName">
                          <vx-tooltip class="d-inline-block" color="primary" text="View report" v-if="tr.Status == 'Completed'">
                            <a @click="tr.Status=='Completed'?getDataEnrichmentJobDetails(tr):showPopup(tr.Status)">{{ data[indextr].JobName }}</a>
                          </vx-tooltip>
                          <a @click="tr.Status=='Completed'?getDataEnrichmentJobDetails(tr):showPopup(tr.Status)" v-else>{{ data[indextr].JobName }}</a>
                        </vs-td>
                        <vs-td :data="data[indextr].JobType">
                          <span @click="tr.Status=='Completed'?getDataEnrichmentJobDetails(tr):showPopup(tr.Status)">{{ data[indextr].JobType }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].RequiredCredits">
                          <span @click="tr.Status == 'Completed' ? getDataEnrichmentJobDetails(tr) : showPopup(tr.Status)">
                            {{ data[indextr].RequiredCredits | tableRecordNumberFormatter }}
                          </span>
                        </vs-td>
                        <vs-td :data="data[indextr].ContactsUploaded">
                          <span @click="tr.Status == 'Completed' ? getDataEnrichmentJobDetails(tr) : showPopup(tr.Status)">
                            {{ data[indextr].ContactsUploaded | tableRecordNumberFormatter }}
                          </span>
                        </vs-td>
                        <vs-td :data="data[indextr].ContactsFound">
                          <span @click="tr.Status == 'Completed' ? getDataEnrichmentJobDetails(tr) : showPopup(tr.Status)">
                            {{ data[indextr].ContactsFound | tableRecordNumberFormatter }}
                          </span>
                        </vs-td>
                        <vs-td :data="data[indextr].CreatedBy">
                          <span @click="tr.Status=='Completed'?getDataEnrichmentJobDetails(tr):showPopup(tr.Status)">{{ data[indextr].CreatedBy }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].DateCreated">
                          <span @click="tr.Status=='Completed'?getDataEnrichmentJobDetails(tr):showPopup(tr.Status)">
                            {{ data[indextr].DateCreated | JobCreatedDateFormat }}
                          </span>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!--Add Data Enrichment Job  modal -->
    <vs-popup
      class="popup-width popup-width-lg add-job-popup close-open"
      title="Add Data Enrichment Job"
      :active.sync="addDataenrichmentPopup"
    >
      <vs-button
        @click="closeImportCvsPopup()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}
      </vs-button>
      <template>
        <div id="backToTop">
          <form data-vv-scope="addJobForm">
            <div class="pt-2">
              <div class="flex mb-8 items-center">
                <div class="mr-2"><h5 class="text-primary">Job Title</h5></div>
                <div class="jobtitle-input">
                  <vs-input
                    name="jobTitleValidation"
                    class="w-full flex-fill"
                    v-model="jobTitle"
                    v-validate="'required'"
                    id="jobTitleId"
                  />
                  <span class="text-danger error-msg" v-if="showJobTitleError">The Job Title entered is already used.  Please choose a new name.</span>
                  <span class="text-danger error-msg" v-else>{{ errors.first("addJobForm.jobTitleValidation") }}</span>
                </div>
              </div>
              <hr />
              <!-- file uploade -->
              <div class="vx-row mb-8">
                <div class="vx-col flex justify-between flex-wrap w-full mt-5">
                  <h5 class="text-primary">Select File</h5>
                  <a href="/sample.csv" download class="text-secondary cursor-pointer"> Download Sample CSV file</a>
                </div>
                <div class="vx-col w-full mt-5">
                  <div class="upload-img flex flex-wrap w-full">
                    <div class="upload-input">
                      <feather-icon icon="UploadCloudIcon" class="primary-c"></feather-icon>
                      <span class="file-text">Upload File</span>
                      <input type="file" ref="file" name="file" @change="loadCSV($event)"/>
                    </div>
                    <span class="file-name" v-if="csvFileName">{{ csvFileName }}</span>
                    <span class="text-danger text-sm" v-if="importContactFileError" >{{ importContactFileError }}</span>
                  </div>
                </div>
              </div>
              <!-- end file uploade -->
              <div class="map-head">
                <h5 class="text-primary">Map Headers to Fields</h5>
                <span class="text-success block mb-2 text-right">Download Limit:<b> {{ totalContactLimitRemaining | tableRecordNumberFormatter }}</b></span>
              </div>
              <hr />
              <div class="vx-row mb-4"> 
                <!-- Contact Identity Fields -->
                <div class="vx-col w-full mt-5 flex justify-between">
                  <p class="text-gray">Contact Identity Fields</p>
                  <span class="text-danger text-sm"> * Required Header</span>
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1 text-danger"> *First Name</span>
                  <v-select
                    v-model="importFirstNameMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                    v-validate="'required'"
                    name="firstName"
                  />
                  <span class="text-danger error-msg">{{ errors.first("addJobForm.firstName") }}</span>
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1 text-danger"> *Last Name</span>
                  <v-select
                    v-model="importLastNameMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                    v-validate="'required'"
                    name="lastName"
                  />
                  <span class="text-danger error-msg">{{ errors.first("addJobForm.lastName") }}</span>
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1 text-danger"> *Email</span>
                  <v-select
                    v-model="importEmailMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                    v-validate="'required'"
                    name="email"
                  />
                  <span class="text-danger error-msg">{{ errors.first("addJobForm.email") }}</span>
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Phone</span>
                  <v-select
                    v-model="importPhoneMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Country</span>
                  <v-select
                    v-model="importCountryMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Address 1</span>
                  <v-select
                    v-model="importAddress1MapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Address 2</span>
                  <v-select
                    v-model="importAddress2MapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> City</span>
                  <v-select
                    v-model="importCityMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Region/State </span>
                  <v-select
                    v-model="importRegionMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Postal Code </span>
                  <v-select
                    v-model="importPostalCodeMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Customer Internal Record Id </span>
                  <v-select
                    v-model="importCustomerRecordIdMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
              </div>
              <div class="vx-row mb-4">
                <!-- Contact's Company Identity Fields  -->
                <div class="vx-col w-full mt-5">
                  <p class="text-gray">Contact's Company Identity Fields</p>
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1 text-danger"> *Company Name</span>
                  <v-select
                    v-model="importCompanyNameMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                    v-validate="'required'"
                    name="companyName"
                  />
                  <span class="text-danger error-msg">{{ errors.first("addJobForm.companyName") }}</span>
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company Website</span>
                  <v-select
                    v-model="importCompanyWebsiteMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company Phone</span>
                  <v-select
                    v-model="importCompanyPhoneMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company Country</span>
                  <v-select
                    v-model="importCompanyCountryMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company Address 1</span>
                  <v-select
                    v-model="importCompanyAddress1MapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company Address 2</span>
                  <v-select
                    v-model="importCompanyAddress2MapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company City</span>
                  <v-select
                    v-model="importCompanyCityMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company Region/State</span>
                  <v-select
                    v-model="importCompanyRegionMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
                <div class="vx-col lg:w-1/4 md:w-1/2 w-full mt-5">
                  <span class="vs-input--label my-1"> Company Postal Code</span>
                  <v-select
                    v-model="importCompanyPostalCodeMapField"
                    :options="importCSVFieldMapOptions"
                    :reduce="(options) => options.id"
                    :clearable="true"
                    label="field"
                    placeholder="Select"
                    class="w-full"
                  />
                </div>
              </div>
              <!-- end Contact's Company Identity Fields -->
            </div>
          </form>
        </div>
      </template>
      <div class="flex w-full float-left mt-6 mb-8">
        <vs-button
          title="Update"
          color="primary"
          type="filled"
          class="ml-auto mr-2"
          @click="checkValidation()"
          :disabled="!isImportContactsButton"
        >{{ LabelConstant.buttonLabelImportContacts }}</vs-button>
        <vs-button
          color="grey"
          @click="closeImportCvsPopup()"
          type="border"
          class="grey-btn mr-0"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
        <!-- Pop up for credits -->
        <vs-popup title="Contact Credits" :active.sync="showCreditPopup">
          <template>
            <div class="vx-row mb-3">
              <div class="vx-col w-full text-center" v-if="noJobRecord">
                <h4 class="text-primary pb-3">Sorry !!</h4>
                <b-card-text><h6>You don't have any contact data in this file.</h6></b-card-text>
              </div>
              <div class="vx-col w-full text-center" v-else-if="jobCredit != null && jobCredit != 0">
                <h4 class="text-primary pb-3">This requires {{ jobCredit }}
                  <span v-if="jobCredit == 1">credit</span>
                  <span v-else>credits</span> to run
                </h4>
                <b-card-text>
                  <p class="pb-3">Credits will only be consumed for records found with contact information.</p>
                  <h6>Do you wish to continue?</h6>
                </b-card-text>
              </div>
              <div class="vx-col w-full text-center" v-else-if="jobCredit == null || jobCredit == 0">
                <h4 class="text-primary pb-3">Sorry !!</h4>
                <b-card-text><h6>You don't have sufficient credit to run</h6></b-card-text>
              </div>
            </div>
          </template>
          <div class="text-right mt-6 mb-8">
            <vs-button
              v-if="jobCredit != null && jobCredit != 0"
              :title="LabelConstant.buttonLabelYes"
              color="primary"
              type="filled"
              class="mr-2"
              @click="showCreditPopup = false"
            >{{ LabelConstant.buttonLabelYes }}</vs-button>
            <vs-button
              v-if="jobCredit != null && jobCredit != 0"
              :title="LabelConstant.buttonLabelNo"
              color="grey"
              type="border"
              class="grey-btn"
              @click="cancelCreditsPopUp()"
            >{{ LabelConstant.buttonLabelNo }}</vs-button>
            <vs-button
              v-if="jobCredit == null || jobCredit == 0"
              :title="LabelConstant.buttonLabelOkay"
              color="primary"
              type="filled"
              class="mr-2"
              @click="cancelCreditsPopUp()"
            >{{ LabelConstant.buttonLabelOkay }}</vs-button>
          </div>
        </vs-popup>
        <!-- Add job submitted Pop up -->
        <vs-popup class="alert-popup" :active.sync="addJobSubmittedPopup">
          <template>
            <div class="vx-row mb-3">
              <div class="vx-col w-full text-center">
                <h2 class="text-primary py-3">The export job was submitted and you will receive an email when the job is completed.</h2>
              </div>
            </div>
          </template>
          <div class="text-center mt-6 mb-8">
            <vs-button
              :title="LabelConstant.buttonLabelYes"
              color="primary"
              type="filled"
              class="mr-2"
              @click="addJobSubmittedPopup=false"
            >{{ LabelConstant.buttonLabelOkay }}</vs-button> 
          </div>
        </vs-popup>
      </div>
    </vs-popup>
    <!--details Data Enrichment Job modal -->
    <vs-popup
      class="popup-width popup-width-lg details-job-popup close-open"
      :title="jobTypeTitle"
      :active.sync="detailDataenrichmentPopup"
    >
      <vs-button
        @click="closedetailDataenrichment()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}
      </vs-button>
      <template>
        <div id="toTop">
          <div class="vx-row">
            <div class="vx-col w-full mb-8 mt-2">
              <p class="text-success">Status: {{ detailDataEnrichmentJob.Status }}</p>
              <h5 class="text-primary">{{ detailDataEnrichmentJob.JobName }}</h5>
            </div>
          </div>
          <div class="vx-row chart-row">
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-8">
              <vx-card class="h-full">
                <h5 class="text-primary">Contacts Found</h5>
                <vue-apex-charts
                  type="pie"
                  height="212"
                  class="contact-found"
                  :options="contactsFound.chartOptions"
                  :series="contactsFound.series"
                ></vue-apex-charts>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-8">
              <vx-card class="h-full">
                <h5 class="text-primary">Contact Info Count</h5>
                <vue-apex-charts
                  type="bar"
                  height="168"
                  :options="contactInfoCount.chartOptions"
                  :series="contactInfoCount.series"
                ></vue-apex-charts>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-8">
              <vx-card class="h-full">
                <vx-tooltip color="primary" text="Contacts captured with work details, i.e. business email, business phone, direct dial, etc.">
                  <h5 class="text-primary">Work Contact</h5>
                </vx-tooltip>
                <vue-apex-charts
                  type="pie"
                  height="212"
                  class="contact-found"
                  :options="workContact.chartOptions"
                  :series="workContact.series"
                ></vue-apex-charts>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full mb-8">
              <vx-card class="h-full">
                <vx-tooltip  color="primary" text="Contacts captured with alternative details, i.e. personal emails, mobile phone, historical email, etc.">
                  <h5 class="text-primary">Alternative Contact</h5>
                </vx-tooltip>
                <vue-apex-charts
                  type="pie"
                  height="212"
                  class="contact-found"
                  :options="altContact.chartOptions"
                  :series="altContact.series"
                ></vue-apex-charts>
              </vx-card>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8">
              <vx-card>
                <h5 class="text-primary mb-3">Top Countries</h5>
                <GChart class="g-map-location"
                  :settings="{ packages: ['corechart', 'table', 'geochart'], 'mapsApiKey': googleMapApiKey }"
                  type="GeoChart"
                  :data="mapData"
                  :options="mapOption"         
                />
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8">
              <vx-card>
                <h5 class="text-primary mb-3">Top Job Levels</h5>
                <vs-table :data="topJobLevelData" :max="5">
                  <template slot="thead">
                    <th>Job Levels</th>
                    <th>Contacts Found</th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].Key">{{ data[indextr].Key }}</vs-td>
                      <vs-td :data="data[indextr].Value">{{ data[indextr].Value | tableRecordNumberFormatter }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8">
              <vx-card>
                <h5 class="text-primary mb-3">Top Job Functions</h5>
                <vs-table :data="topJobFunctionData" :max="5">
                  <template slot="thead">
                    <th>Job Function</th>
                    <th>Contacts Found</th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].Key">{{ data[indextr].Key }}</vs-td>
                      <vs-td :data="data[indextr].Value">{{ data[indextr].Value | tableRecordNumberFormatter }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8">
              <vx-card class="top-industry">
                <h5 class="text-primary mb-3">Top Industry</h5>
                <vs-table :data="topIndustryData" :max="5">
                  <template slot="thead">
                    <th>Industry</th>
                    <th>Contacts Found</th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].Key">{{ data[indextr].Key }}</vs-td>
                      <vs-td :data="data[indextr].Value">{{ data[indextr].Value | tableRecordNumberFormatter }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8">
              <vx-card>
                <h5 class="text-primary">Top Revenue Bands</h5>
                <vue-apex-charts
                  type="bar"
                  height="250"
                  :options="topRevenueBand.chartOptions"
                  :series="topRevenueBand.series"
                ></vue-apex-charts>
              </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8">
              <vx-card>
                <h5 class="text-primary">Top Employee Size</h5>
                <vue-apex-charts
                  type="bar"
                  :options="topEmployeeSize.chartOptions"
                  :series="topEmployeeSize.series"
                ></vue-apex-charts>
              </vx-card>
            </div>
          </div>
          <!-- end file uploade -->
        </div>
      </template>
      <div class="flex w-full float-left mt-6 mb-8">
        <vs-button
          title="download"
          color="primary"
          icon-pack="feather"
          icon="icon-download"
          type="filled"
          class="ml-auto mr-2"
          @click="getDetailDataEnrichmentJobsDownload(detailDataEnrichmentRecord)"
        >{{ LabelConstant.buttonLabelDownload }}</vs-button>
        <vs-button
          color="grey"
          @click="closedetailDataenrichment()"
          type="border"
          class="grey-btn mr-0"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
      </div>
    </vs-popup>
    <!-- status popup -->
    <find-any-one-status-popup
      :isStatusPopup="isStatusPopup"
      :jobStatusIcon="jobStatusIcon"
      :jobStatusMessage="jobStatusMessage"
      @clickForFinish="clickForFinish"
    ></find-any-one-status-popup>
  </div>
</template>

  <script>
import InputTag from "vue-input-tag";
import { BMedia, BImg, BCardText } from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import VxCard from "../../components/vx-card/VxCard.vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { Validator } from "vee-validate";
import FindAnyOneStatusPopup from "../../components/modal/FindAnyOneStatusPopup.vue";
import { GChart } from 'vue-google-charts'

const dict = {
  custom: {
    jobTitleValidation: {
      required: "Please enter job title",
    },
    firstName: {
      required: "Please select header mapping",
    },
    lastName: {
      required: "Please select header mapping",
    },
    email: {
      required: "Please select header mapping",
    },
    companyName: {
      required: "Please select header mapping",
    },
  },
};
Validator.localize("en", dict);

const themeColors = ["#044d80", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"];
const CustomColors = ["#266591", "#266591", "#266591", "#266591", "#266591"];
export default {
  components: {
    InputTag,
    BMedia,
    BImg,
    BCardText,
    "v-select": vSelect,
    Datepicker,
    VxCard,
    VueApexCharts,
    FindAnyOneStatusPopup,
    GChart,
  },
  data() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    return{
    googleMapApiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    mapData: [['Country', 'Jobs'],['',0]],
    mapOption: {        
      'height':255,
      colorAxis:{colors: [token[uid].themeDetail.tempChartColor1]},
    },
    // duplicate Job title
    showJobTitleError:false,
    addDataenrichmentPopup: false,
    detailDataenrichmentPopup: false,
    jobList: [],
    showCreditPopup: false,
    // popup for status
    isStatusPopup: false,
    jobStatusIcon: "",
    jobStatusMessage: "",
    //CSV File
    isImportContactsButton: false,
    addJobSubmittedPopup: false,
    csvFileName: null,
    importContactFileError: null,
    csv: null,
    jobTitle: null,
    //Store list of csv fields for mapping dropdown
    contactJobRecords: [],
    importCSVFieldMapOptions: [],
    importContactJobRecords: [],
    //Store selected field from csv for mapping
    //For Contacy Identity fields
    importFirstNameMapField: null,
    importLastNameMapField: null,
    importEmailMapField: null,
    importPhoneMapField: null,
    importCountryMapField: null,
    importAddress1MapField: null,
    importAddress2MapField: null,
    importCityMapField: null,
    importRegionMapField: null,
    importPostalCodeMapField: null,
    importCustomerRecordIdMapField: null,
    // for Contact's Company Identity Fields
    importCompanyNameMapField: null,
    importCompanyWebsiteMapField: null,
    importCompanyPhoneMapField: null,
    importCompanyCountryMapField: null,
    importCompanyAddress1MapField: null,
    importCompanyAddress2MapField: null,
    importCompanyCityMapField: null,
    importCompanyRegionMapField: null,
    importCompanyPostalCodeMapField: null,
    //record count number of credits
    recordCount: null,
    noJobRecord:false,
    headerCount: null,
    dataEnrichmentJob_Id: null,
    // Total Contact Limit
    totalContactLimitRemaining: 0,
    jobCredit: null,
    // detail DataEnrichment Job
    detailDataEnrichmentJob: {},
    detailDataEnrichmentRecord: null,
    jobTypeTitle:null,
    //  values for chart
    //  Contacts Found
    contactsFound: {
      series: [],
      chartOptions: {
        labels: [],
        colors: themeColors,
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom ",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      },
    },
    // Contact Info Count
    contactInfoCount: {
      series: [],
      chartOptions: {
        colors: themeColors,
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "77%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      },
    },
    // Work Contact
    workContact: {
      series: [10,10],
      // series: [],
      chartOptions: {
        labels: [],
        colors: themeColors,
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },

              legend: {
                position: "bottom ",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      },
    },
    // Alternative Contact
    altContact: {
      // series: [],
      series: [10,10],
      chartOptions: {
        labels: [],
        colors: themeColors,
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },

              legend: {
                position: "bottom ",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      },
    },
    // Top Countries
    // topCountryData:[],
    // Top Job Levels
    topJobLevelData:[],
    // Top Job Functions
    topJobFunctionData:[],
    // Top Industry
    topIndustryData:[],
    // Top Revenue Bands
    topRevenueBand: {
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          width: "90%",
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        colors: CustomColors,
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 20,
          textAnchor: "start",
          style: {
            colors: ["#bbbaba"],
          },
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        xaxis: {
          categories: [],

          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      },
    },
    // Top Company Employee Size
    topEmployeeSize: {
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          width: "90%",
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        colors: CustomColors,
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 20,
          textAnchor: "start",
          style: {
            colors: ["#bbbaba"],
          },
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        xaxis: {
          categories: [],

          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      },
    },
  }},
  mounted() {
    this.getDataEnrichmentJobs();
    this.GetContactLimits();
    window.setInterval(() => {
      this.axios.get("/ws/DataEnrichmentJobs/GetDataEnrichmentJobList")
        .then((response) => {
          this.jobList = response.data;
        })
    }, 1000*60);
  },
  methods: {
    // for showing status popup
    showPopup(jobStatus) {
      // for message and icon
      if (jobStatus == "Errored") {
        this.jobStatusIcon = "error";
        this.jobStatusMessage = "You have some error in uploading job records.";
      } else if (jobStatus == "Not_Set") {
        this.jobStatusIcon = "error";
        this.jobStatusMessage = "Your job records are not set properly.";
      } else if (jobStatus == "Pending") {
        this.jobStatusIcon = "MoreHorizontalIcon";
        this.jobStatusMessage = "Your job status is pending";
      } else if (jobStatus == "Validating") {
        this.jobStatusIcon = "timelapse";
        this.jobStatusMessage = "Your job records are validating, Please wait";
      } else if (jobStatus == "Running") {
        this.jobStatusIcon = "timelapse";
        this.jobStatusMessage = "Your job records are running, Please wait";
      } else if (jobStatus == "Downloaded") {
        this.jobStatusIcon = "DownloadIcon";
        this.jobStatusMessage = "Your job records are downloading, Please wait";
      } else if (jobStatus == "Uploading") {
        this.jobStatusIcon = "UploadIcon";
        this.jobStatusMessage = "Your job records are uploading, Please wait";
      } else {
        this.jobStatusIcon = "error";
        this.jobStatusMessage = "Sorry!! These is some error";
      }
      this.isStatusPopup = true;
    },
    // for close status popup
    clickForFinish() {
      this.isStatusPopup = false;
      this.jobStatusIcon = "";
      this.jobStatusMessage = "";
    },
    // job list
    async getDataEnrichmentJobs() {
      this.$vs.loading();
      await this.axios.get("/ws/DataEnrichmentJobs/GetDataEnrichmentJobList")
        .then((response) => {
          let data = response.data;
          this.jobList = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // download job detail file
    async getDataEnrichmentJobsDownload(job) {
      let jobId = job.DataEnrichmentJob_Id;
      this.$vs.loading();
      await this.axios.get("/ws/DataEnrichmentJobs/GetDataEnrichmentDownloadById?dataenrichmentjob_id=" +jobId)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/csv",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "DataEnrichmentJobs.csv");
          document.body.appendChild(link);
          link.click();
          job.ReportDownloaded = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // Open job detail popup
    async getDataEnrichmentJobDetails(job) {
      this.workContact.series = [];
      this.workContact.chartOptions.labels = [];
      this.altContact.series = [];
      this.altContact.chartOptions.labels = [];

      let jobId = job.DataEnrichmentJob_Id;
      this.detailDataEnrichmentRecord = job;
      this.$vs.loading();
      await this.axios.get("/ws/DataEnrichmentJobDetail/GetDataEnrichmentJobDetail?dataEnrichmentJob_Id=" +jobId)
        .then((response) => {
          let data = response.data;
          this.detailDataEnrichmentJob = data;
          if(this.detailDataEnrichmentJob.JobType=='Upload'){
            this.jobTypeTitle="Data Enrichment Job Detail"
          }else{
            this.jobTypeTitle="Query Search Job Detail"
          }
          this.detailDataenrichmentPopup = true;
          //Contacts Found
          if (this.detailDataEnrichmentJob.ContactsFound) {
            let key = [];
            for (let i = 0;i < this.detailDataEnrichmentJob.ContactsFound.length;i++) {
              this.contactsFound.series.push(this.detailDataEnrichmentJob.ContactsFound[i].Value);
              key.push(this.detailDataEnrichmentJob.ContactsFound[i].Key);
            }
            this.contactsFound.chartOptions.labels;
            this.contactsFound.chartOptions = {
              ...this.contactsFound.chartOptions,
              labels: key,
            };
          }
          // Contact Info Count
          // for Email
          if (this.detailDataEnrichmentJob.ContactInfoCount_Email) {
            this.contactInfoCount.chartOptions.xaxis.categories.push("Email");
            for (let i = 0;i < this.detailDataEnrichmentJob.ContactInfoCount_Email.length;i++) {
              let obj = {};
              obj.name = this.detailDataEnrichmentJob.ContactInfoCount_Email[i].Key;
              obj.data = [];
              obj.data.push(this.detailDataEnrichmentJob.ContactInfoCount_Email[i].Value);
              this.contactInfoCount.series.push(obj);
            }
          }
          // for Phone
          if (this.detailDataEnrichmentJob.ContactInfoCount_Phone) {
            if (!this.detailDataEnrichmentJob.ContactInfoCount_Email) {
              for (let i = 0;i < this.detailDataEnrichmentJob.ContactInfoCount_Phone.length;i++) {
                let obj = {};
                obj.name =this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Key;
                obj.data = [];
                obj.data.push(this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Value);
                this.contactInfoCount.series.push(obj);
              }
            } else {
              for (let i = 0;i < this.detailDataEnrichmentJob.ContactInfoCount_Phone.length;i++) {
                if (this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Key =="Work") {
                  for (let j = 0; j < this.contactInfoCount.series.length; j++) {
                    if (this.contactInfoCount.series[j].name == "Work") {
                      this.contactInfoCount.series[j].data.push(this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Value);
                    }
                  }
                } else if (this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Key =="Alternative") {
                  for (let j = 0; j < this.contactInfoCount.series.length; j++) {
                    if (this.contactInfoCount.series[j].name == "Alternative") {
                      this.contactInfoCount.series[j].data.push(this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Value);
                    }
                  }
                } else {
                  let obj = {};
                  obj.name =this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Key;
                  obj.data = [];
                  obj.data.push(this.detailDataEnrichmentJob.ContactInfoCount_Phone[i].Value);
                  this.contactInfoCount.series.push(obj);
                }
              }
            }
            this.contactInfoCount.chartOptions.xaxis.categories.push("Phone");
          }
          // Work Contact
          if (this.detailDataEnrichmentJob.WorkContactInfo) {
            if(this.detailDataEnrichmentJob.WorkContactInfo.length!=0){
              let key = [];
              let foundValue=0
              for (let i = 0; i < this.detailDataEnrichmentJob.WorkContactInfo.length; i++) {
                if(this.detailDataEnrichmentJob.WorkContactInfo[i].Key=="Found"){
                  foundValue=this.detailDataEnrichmentJob.WorkContactInfo[i].Value
                  this.workContact.series.push(this.detailDataEnrichmentJob.WorkContactInfo[i].Value);
                  key.push(this.detailDataEnrichmentJob.WorkContactInfo[i].Key);
                }else if(this.detailDataEnrichmentJob.WorkContactInfo[i].Key=="Total"){
                  this.workContact.series.push(this.detailDataEnrichmentJob.WorkContactInfo[i].Value-foundValue);
                  key.push("Not Found");
                }else{
                  this.workContact.series.push(this.detailDataEnrichmentJob.WorkContactInfo[i].Value);
                  key.push(this.detailDataEnrichmentJob.WorkContactInfo[i].Key);
                }
              }
              // this.workContact.chartOptions.labels;
              this.workContact.chartOptions = {
                ...this.workContact.chartOptions,
                labels: key,
              };
            }
            else{
              this.workContact.series.push(0,0);
              this.workContact.chartOptions = {
                ...this.workContact.chartOptions,
                labels: ["Found","Not Found"],
              };
            }
          }
          // Alt Contact
          if (this.detailDataEnrichmentJob.AltContactInfo) {
            if(this.detailDataEnrichmentJob.AltContactInfo.length==0){
              this.altContact.series.push(0,0);
              this.altContact.chartOptions = {
                ...this.altContact.chartOptions,
                labels: ["Found","Not Found"],
              };
            }else{
              let key = [];
              let foundValue=0
              for (let i = 0; i < this.detailDataEnrichmentJob.AltContactInfo.length; i++) {
                if(this.detailDataEnrichmentJob.AltContactInfo[i].Key=="Found"){
                  foundValue=this.detailDataEnrichmentJob.AltContactInfo[i].Value
                  this.altContact.series.push(this.detailDataEnrichmentJob.AltContactInfo[i].Value);
                  key.push(this.detailDataEnrichmentJob.AltContactInfo[i].Key);
                }else if(this.detailDataEnrichmentJob.AltContactInfo[i].Key=="Total"){
                  this.altContact.series.push(this.detailDataEnrichmentJob.AltContactInfo[i].Value-foundValue);
                  key.push("Not Found");
                }else{
                  this.altContact.series.push(this.detailDataEnrichmentJob.AltContactInfo[i].Value);
                  key.push(this.detailDataEnrichmentJob.AltContactInfo[i].Key);
                }
              }
              // this.altContact.chartOptions.labels;
              this.altContact.chartOptions = {
                ...this.altContact.chartOptions,
                labels: key,
              };
            }
          }

          // Top Countries Map
          if (this.detailDataEnrichmentJob.TopCountry) {
            this.mapData= [['Country', 'Jobs']];
            if(this.detailDataEnrichmentJob.TopCountry.length!=0){
              for (let i = 0; i < this.detailDataEnrichmentJob.TopCountry.length;i++){
                let data = [];
                data.push(this.detailDataEnrichmentJob.TopCountry[i].Key.replace( /"|\[|\]/g,""))
                data.push(this.detailDataEnrichmentJob.TopCountry[i].Value)
                this.mapData.push(data)
              }
            }else{
              let data = ['',0];
              this.mapData.push(data)
            }
          }

          // Top Job Levels
          if (this.detailDataEnrichmentJob.TopJobLevel) {
            this.topJobLevelData=this.detailDataEnrichmentJob.TopJobLevel
            for (let i = 0; i < this.topJobLevelData.length;i++){
              this.topJobLevelData[i].Key=this.topJobLevelData[i].Key.replace(/"|\[|\]/g,"")
            }
          }

          // Top Job Functions
          if (this.detailDataEnrichmentJob.TopJobFunction) {
            this.topJobFunctionData=this.detailDataEnrichmentJob.TopJobFunction
            for (let i = 0; i < this.topJobFunctionData.length;i++){
              this.topJobFunctionData[i].Key=this.topJobFunctionData[i].Key.replace(/"|\[|\]/g,"")
            }
          }

          // Top Industry
          if (this.detailDataEnrichmentJob.TopIndustry) {
            this.topIndustryData=this.detailDataEnrichmentJob.TopIndustry
            for (let i = 0; i < this.topIndustryData.length;i++){
              this.topIndustryData[i].Key=this.topIndustryData[i].Key.replace(/"|\[|\]/g,"")
            }
          }

          // Top Revenue Bands
          if (this.detailDataEnrichmentJob.TopRevenueBand) {
            let key = [];
            for (let i = 0;i < this.detailDataEnrichmentJob.TopRevenueBand.length;i++) {
              if (i == 5) {
                break;
              }
              this.topRevenueBand.series[0].data.push(this.detailDataEnrichmentJob.TopRevenueBand[i].Value);
              key.push(this.detailDataEnrichmentJob.TopRevenueBand[i].Key.replace(/"|\[|\]/g,""));
            }
            this.topRevenueBand.chartOptions = {
              ...this.topRevenueBand.chartOptions,
              ...{
                xaxis: {
                  categories: key,
                },
              },
            };
          }

          // Top Company Employee Size
          if (this.detailDataEnrichmentJob.TopEmployeeSize) {
            let key = [];
            for (let i = 0;i < this.detailDataEnrichmentJob.TopEmployeeSize.length;i++) {
              if (i == 5) {
                break;
              }
              this.topEmployeeSize.series[0].data.push(this.detailDataEnrichmentJob.TopEmployeeSize[i].Value);
              key.push(this.detailDataEnrichmentJob.TopEmployeeSize[i].Key.replace(/"|\[|\]/g,""));
            }
            this.topEmployeeSize.chartOptions = {
              ...this.topEmployeeSize.chartOptions,
              ...{
                xaxis: {
                  categories: key,
                },
              },
            };
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    // close detail data enrichment popup
    closedetailDataenrichment() {
      this.jobTypeTitle=null;
      this.detailDataenrichmentPopup = false;
      this.detailDataEnrichmentJob = {};
      this.detailDataEnrichmentRecord = null;
      //  Clear values of chart
      this.contactsFound.series = [];
      this.contactsFound.chartOptions.labels = [];

      this.contactInfoCount.series = [];
      this.contactInfoCount.chartOptions.xaxis.categories = [];

      this.workContact.series = [];
      this.workContact.chartOptions.labels = [];

      this.altContact.series = [];
      this.altContact.chartOptions.labels = [];

      this.topJobLevelData=[]
      this.topJobFunctionData=[]
      this.topIndustryData=[]

      this.mapData= [['Country', 'Jobs'],['',0]],

      this.topRevenueBand.series[0].data = [];
      this.topRevenueBand.chartOptions.xaxis.categories = [];

      this.topEmployeeSize.series[0].data = [];
      this.topEmployeeSize.chartOptions.xaxis.categories = [];
      
      const element = document.getElementById("toTop");
      element.scrollIntoView();
    },
    // Download job detail and close popup
    getDetailDataEnrichmentJobsDownload(job) {
      this.getDataEnrichmentJobsDownload(job);
      this.closedetailDataenrichment();
    },
    // Load CSv File
    loadCSV(e) {
      //For Contacy Identity fields
        this.importFirstNameMapField = null;
        this.importLastNameMapField = null;
        this.importEmailMapField = null;
        this.importPhoneMapField = null;
        this.importCountryMapField = null;
        this.importAddress1MapField = null;
        this.importAddress2MapField = null;
        this.importCityMapField = null;
        this.importRegionMapField = null;
        this.importPostalCodeMapField = null;
        this.importCustomerRecordIdMapField = null;
        // for Contact's Company Identity Fields
        this.importCompanyNameMapField = null;
        this.importCompanyWebsiteMapField = null;
        this.importCompanyPhoneMapField = null;
        this.importCompanyCountryMapField = null;
        this.importCompanyAddress1MapField = null;
        this.importCompanyAddress2MapField = null;
        this.importCompanyCityMapField = null;
        this.importCompanyRegionMapField = null;
        this.importCompanyPostalCodeMapField = null;
        setTimeout(() => {
          this.errors.clear("addJobForm");
        }, 20);

      if (this.$refs.file.files[0].type == "text/csv" || this.$refs.file.files[0].type == "application/vnd.ms-excel") {
        this.isImportContactsButton = true;
        this.csvFileName = this.$refs.file.files[0].name;
        this.importContactFileError = null;
        this.showCreditPopup = true;
        this.importCSVFieldMapOptions = [];
        this.contactJobRecords=[]
        this.jobCredit = null
        this.recordCount = null;
      } else {
        this.importContactFileError = "Please upload CSV file only.";
        this.$refs.file.value = "";
        this.csvFileName = null;
        //For Contacy Identity fields
        this.importFirstNameMapField = null;
        this.importLastNameMapField = null;
        this.importEmailMapField = null;
        this.importPhoneMapField = null;
        this.importCountryMapField = null;
        this.importAddress1MapField = null;
        this.importAddress2MapField = null;
        this.importCityMapField = null;
        this.importRegionMapField = null;
        this.importPostalCodeMapField = null;
        this.importCustomerRecordIdMapField = null;
        // for Contact's Company Identity Fields
        this.importCompanyNameMapField = null;
        this.importCompanyWebsiteMapField = null;
        this.importCompanyPhoneMapField = null;
        this.importCompanyCountryMapField = null;
        this.importCompanyAddress1MapField = null;
        this.importCompanyAddress2MapField = null;
        this.importCompanyCityMapField = null;
        this.importCompanyRegionMapField = null;
        this.importCompanyPostalCodeMapField = null;
        this.importCSVFieldMapOptions = [];
        this.contactJobRecords=[]
        this.isImportContactsButton = false;
        return false;
      }
      var vm = this;
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onload = function (event) {
          var csv = event.target.result;
          vm.csv = vm.csvJSON(csv);
        };
      } else {
        alert("File Reader are not supported in this browser!");
      }
    },
    // Get records and header form CSV file
    csvJSON(csv) {
      var vm = this;
      var lines;
      if(csv.includes("\r\n")){
        lines = csv.split("\r\n");
      } else {
        lines = csv.split("\n");
      }
      var result = [];
      var headers = lines[0].replace( /"/g,"").split(",");
      var mapHeader=[];
      for(let i=0; i<headers.length;i++){
        mapHeader.push(headers[i].toLowerCase().replace( /"|_| /g,""))
      }
      
      for(let i=0; i<mapHeader.length;i++){
        if(mapHeader[i]=="firstname" || mapHeader[i]=="contactfirstname"){
          this.importFirstNameMapField=i;
        }
        if(mapHeader[i]=="lastname" || mapHeader[i]=="surname" || mapHeader[i]=="contactlastname"){
          this.importLastNameMapField=i;
        }
        if(mapHeader[i]=="email" || mapHeader[i]=="contactemail"){
          this.importEmailMapField=i;
        }
        if(mapHeader[i]=="phone" || mapHeader[i]=="mobilephone" || mapHeader[i]=="cellphone" || mapHeader[i]=="cell" || mapHeader[i]=="mobile" 
        || mapHeader[i]=="contactphone" || mapHeader[i]=="contactmobilephone"){
          this.importPhoneMapField=i;
        }
        if(mapHeader[i]=="country"){
          this.importCountryMapField=i;
        }
        if(mapHeader[i]=="address1" || mapHeader[i]=="address" || mapHeader[i]=="location"){
          this.importAddress1MapField=i;
        }
        if(mapHeader[i]=="address2"){
          this.importAddress2MapField=i;
        }
        if(mapHeader[i]=="city"){
          this.importCityMapField=i;
        }
        if(mapHeader[i]=="region" || mapHeader[i]=="state"){
          this.importRegionMapField=i;
        }
        if(mapHeader[i]=="postal" || mapHeader[i]=="postalcode" || mapHeader[i]=="zip"  || mapHeader[i]=="zipcode"){
          this.importPostalCodeMapField=i;
        }
        if(mapHeader[i]=="recordid" || mapHeader[i]=="internalid" || mapHeader[i]=="internalrecordid"){
          this.importCustomerRecordIdMapField=i;
        }
        if(mapHeader[i]=="companyname"){
          this.importCompanyNameMapField=i;
        }
        if(mapHeader[i]=="companywebsite"){
          this.importCompanyWebsiteMapField=i;
        }
        if(mapHeader[i]=="companyphone"){
          this.importCompanyPhoneMapField=i;
        }
        if(mapHeader[i]=="companycountry"){
          this.importCompanyCountryMapField=i;
        }
        if(mapHeader[i]=="companyaddress1" || mapHeader[i]=="companyaddress" || mapHeader[i]=="companylocation"){
          this.importCompanyAddress1MapField=i;
        }
        if(mapHeader[i]=="companyaddress2"){
          this.importCompanyAddress2MapField=i;
        }
        if(mapHeader[i]=="companycity"){
          this.importCompanyCityMapField=i;
        }
        if(mapHeader[i]=="companyregion"  || mapHeader[i]=="companystate"){
          this.importCompanyRegionMapField=i;
        }
        if(mapHeader[i]=="companypostalcode" || mapHeader[i]=="companypostal" || mapHeader[i]=="companyzip"  || mapHeader[i]=="companyzipcode"){
          this.importCompanyPostalCodeMapField=i;
        }        
      }

      //   var headers = [];
      // let headerForFilter = [];
      // if(lines[0].includes("\"")){
      //   headerForFilter = lines[0].split("\"").filter(checkHeaders);

        // for(let j=0;j<headerForFilter.length;j++){
        //   let lastIndex=headerForFilter[j].length-1
        //   let item=[]
        //   if(headerForFilter[j][0]=="," && headerForFilter[j][lastIndex]==","){
            // item=headerForFilter[j].slice(1,-1).split(",")
        //   }else if(headerForFilter[j][0]=="," && headerForFilter[j]!=","){
        //     item=headerForFilter[j].slice(1).split(",")
        //   }else if(headerForFilter[j][lastIndex]=="," && headerForFilter[j]!=","){
        //     item=headerForFilter[j].slice(0,-1).split(",")
        //   }else{
        //     item.push(headerForFilter[j])
        //   }
        //   for(let i=0;i<item.length;i++){
        //     headers.push(item[i])
        //   }
        // }
      // }else{
      //   headerForFilter.push(lines[0])
      //   headers = lines[0].split(",");
      // }
      // function checkHeaders(arr) {
      //   return arr != ",";
      // }

      for (let i = 1; i < lines.length - 1; i++) {
        let jobRecords = [];
        if(lines[i].includes("\"")){
          jobRecords = lines[i].split("\"").filter(checkHeaders)
          let records=[]
          
          for(let j=0;j<jobRecords.length;j++){
            let lastIndex=jobRecords[j].length-1
            let record=[]
            if(jobRecords[j][0]=="," && jobRecords[j][lastIndex]==","){
              record=jobRecords[j].slice(1,-1).split(",")
            }else if(jobRecords[j][0]=="," && jobRecords[j]!=","){
              record=jobRecords[j].slice(1).split(",") 
            }else if(jobRecords[j][lastIndex]=="," && jobRecords[j]!=","){
              record=jobRecords[j].slice(0,-1).split(",")
            }else{
              records.push(jobRecords[j]);
            }

            for(let i=0;i<record.length;i++){
              records.push(record[i])
            }
          }
          this.contactJobRecords.push(records);
        }else{
          this.contactJobRecords.push(lines[i].split(","));
        }
      }
      function checkHeaders(arr) {
        return arr != ",";
      }

      for (let i = 0; i < headers.length; i++) {
        vm.importCSVFieldMapOptions.push({
          id: i,
          field: headers[i],
        });
      }
      lines.map(function (line, indexLine) {
        if (indexLine < 1) return;
        var obj = {};
        var currentline = line.split(",");
        headers.map(function (header, indexHeader) {
          obj[header] = currentline[indexHeader];
        });
        result.push(obj);
      });

      this.headerCount = headers.length;
      this.recordCount = this.contactJobRecords.length;
      if(this.recordCount==0){
        this.noJobRecord=true
      }
      if (this.totalContactLimitRemaining >= this.recordCount) {
        this.jobCredit = this.recordCount;
      }
      result.pop();
      return result;
    },
    // clear dat when close popup
    clearData() {
      this.$refs.file.value = "";
      this.csvFileName = null;
      this.isImportContactsButton = false;
      this.csv = null;

      this.importContactFileError = null;
      this.contactJobRecords = [];
      this.importCSVFieldMapOptions = [];
      this.importContactJobRecords = [];

      this.headerCount = null;
      this.recordCount = null;
      this.jobCredit = null;
      this.noJobRecord=false
      //For Contacy Identity fields
      this.importFirstNameMapField = null;
      this.importLastNameMapField = null;
      this.importEmailMapField = null;
      this.importPhoneMapField = null;
      this.importCountryMapField = null;
      this.importAddress1MapField = null;
      this.importAddress2MapField = null;
      this.importCityMapField = null;
      this.importRegionMapField = null;
      this.importPostalCodeMapField = null;
      this.importCustomerRecordIdMapField = null;
      // for Contact's Company Identity Fields
      this.importCompanyNameMapField = null;
      this.importCompanyWebsiteMapField = null;
      this.importCompanyPhoneMapField = null;
      this.importCompanyCountryMapField = null;
      this.importCompanyAddress1MapField = null;
      this.importCompanyAddress2MapField = null;
      this.importCompanyCityMapField = null;
      this.importCompanyRegionMapField = null;
      this.importCompanyPostalCodeMapField = null;
    },
    // for close creadit pop up
    cancelCreditsPopUp() {
      this.showCreditPopup = false;
      setTimeout(() => {
        this.clearData();
      }, 20);
      setTimeout(() => {
        this.errors.clear("addJobForm");
      }, 50);
    },
    //check validations
    checkValidation() {
      this.$validator.validateAll("addJobForm").then(async (result) => {
        if (result) {
          this.addDataEnrichmentJob();
        }
      });
    },
    // Import Contacts
    async addDataEnrichmentJob() {
      let lastBatch = null;
      let dataEnrichmentJobId = null;
      let recordsBatchs = null;
      let addJobData = {};
      let allAddJobData = [];
      this.importContactJobRecords=[];

      // if user not select any field value, that is null
      let selectedField = {
        firstName: this.importFirstNameMapField != null ? this.importFirstNameMapField : null,
        lastName: this.importLastNameMapField != null ? this.importLastNameMapField : null,
        email: this.importEmailMapField != null ? this.importEmailMapField : null,
        phone: this.importPhoneMapField != null ? this.importPhoneMapField : null,
        country: this.importCountryMapField != null ? this.importCountryMapField : null,
        address1: this.importAddress1MapField != null ? this.importAddress1MapField : null,
        address2: this.importAddress2MapField != null ? this.importAddress2MapField : null,
        city: this.importCityMapField != null ? this.importCityMapField : null,
        region: this.importRegionMapField != null ? this.importRegionMapField : null,
        postal: this.importPostalCodeMapField != null ? this.importPostalCodeMapField : null,
        id: this.importCustomerRecordIdMapField != null ? this.importCustomerRecordIdMapField : null,
        companyName: this.importCompanyNameMapField != null ? this.importCompanyNameMapField : null,
        companyWebsite: this.importCompanyWebsiteMapField != null ? this.importCompanyWebsiteMapField : null,
        companyPhone: this.importCompanyPhoneMapField != null ? this.importCompanyPhoneMapField : null,
        companyCountry: this.importCompanyCountryMapField != null ? this.importCompanyCountryMapField : null,
        companyAddress1: this.importCompanyAddress1MapField != null ? this.importCompanyAddress1MapField : null,
        companyAddress2: this.importCompanyAddress2MapField != null ? this.importCompanyAddress2MapField : null,
        companyCity: this.importCompanyCityMapField != null ? this.importCompanyCityMapField : null,
        companyRegion: this.importCompanyRegionMapField != null ? this.importCompanyRegionMapField : null,
        companyPostal: this.importCompanyPostalCodeMapField != null ? this.importCompanyPostalCodeMapField : null,
      };

      if (this.recordCount <= 5000) {
        lastBatch = true;
        dataEnrichmentJobId = 0;

        for (let i = 0; i < this.contactJobRecords.length; i++) {
          let obj = {
            FirstName: selectedField.firstName != null ? this.contactJobRecords[i][selectedField.firstName] : null,
            LastName: selectedField.lastName != null ? this.contactJobRecords[i][selectedField.lastName] : null,
            Email: selectedField.email != null ? this.contactJobRecords[i][selectedField.email] : null,
            Phone: selectedField.phone != null ? this.contactJobRecords[i][selectedField.phone] : null,
            Address1: selectedField.address1 != null ? this.contactJobRecords[i][selectedField.address1] : null,
            Address2: selectedField.address2 != null ? this.contactJobRecords[i][selectedField.address2] : null,
            City: selectedField.city != null ? this.contactJobRecords[i][selectedField.city] : null,
            Region: selectedField.region != null ? this.contactJobRecords[i][selectedField.region] : null,
            PostalCode: selectedField.postal != null ? this.contactJobRecords[i][selectedField.postal] : null,
            Country: selectedField.country != null ? this.contactJobRecords[i][selectedField.country] : null,
            CompanyName: selectedField.companyName != null ? this.contactJobRecords[i][selectedField.companyName] : null,
            CompanyAddress1: selectedField.companyAddress1 != null ? this.contactJobRecords[i][selectedField.companyAddress1] : null,
            CompanyAddress2: selectedField.companyAddress2 != null ? this.contactJobRecords[i][selectedField.companyAddress2] : null,
            CompanyCity: selectedField.companyCity != null ? this.contactJobRecords[i][selectedField.companyCity] : null,
            CompanyRegion: selectedField.companyRegion != null ? this.contactJobRecords[i][selectedField.companyRegion] : null,
            CompanyPostal: selectedField.companyPostal != null ? this.contactJobRecords[i][selectedField.companyPostal] : null,
            CompanyCountry: selectedField.companyCountry != null ? this.contactJobRecords[i][selectedField.companyCountry] : null,
            CompanyPhone: selectedField.companyPhone != null ? this.contactJobRecords[i][selectedField.companyPhone] : null,
            CompanyWebsite: selectedField.companyWebsite != null ? this.contactJobRecords[i][selectedField.companyWebsite] : null,
            Internal_Record_Id: selectedField.id != null ? this.contactJobRecords[i][selectedField.id] : null,
          };
          this.importContactJobRecords.push(obj);
        }
        addJobData = {
          JobName: this.jobTitle,
          LastBatch: lastBatch,
          DataEnrichmentJob_Id: dataEnrichmentJobId,
          ContactJobRecords: this.importContactJobRecords,
          ContactUserDefinedTags: null,
          ContactSavedFrom: "enrichment",
          IsExport: true,
        };
        this.$vs.loading();
        await this.axios.post("/ws/DataEnrichmentAddJob/RunDataEnrichmentJob", addJobData)
          .then((response) => {
            this.dataEnrichmentJob_Id = response.data.DataEnrichmentJob_Id;
            if(response.data.UploadCompleted== true && this.UploadMessage1!="duplicate found"){
              this.addDataenrichmentPopup = false;
              this.closeImportCvsPopup();
              this.getDataEnrichmentJobs();
              this.$vs.notify({
                title: "Success",
                text: response.data.UploadMessage,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.addJobSubmittedPopup=true
            }else if(response.data.UploadCompleted== false && response.data.UploadMessage=="duplicate found"){
              this.showJobTitleError=true
              document.getElementById("jobTitleId").focus();
            }
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          });
      } else if (this.recordCount > 5000) {
        recordsBatchs = Math.ceil(this.recordCount / 5000);

        for (let batch = 1; batch <= recordsBatchs; batch++) {
          this.importContactJobRecords=[]
          // lastbatch value
          if (batch < recordsBatchs) {
            lastBatch = false;
          } else {
            lastBatch = true;
          }

          // lastBatch = batch < recordsBatchs ? false : true;

          for (let i = 5000 * (batch - 1); i < 5000 * batch; i++) {
            if (i == this.contactJobRecords.length) {
              break;
            }
            let obj = {
              FirstName: selectedField.firstName != null ? this.contactJobRecords[i][selectedField.firstName] : null,
              LastName: selectedField.lastName != null ? this.contactJobRecords[i][selectedField.lastName] : null,
              Email: selectedField.email != null ? this.contactJobRecords[i][selectedField.email] : null,
              Phone: selectedField.phone != null ? this.contactJobRecords[i][selectedField.phone] : null,
              Address1: selectedField.address1 != null ? this.contactJobRecords[i][selectedField.address1] : null,
              Address2: selectedField.address2 != null ? this.contactJobRecords[i][selectedField.address2] : null,
              City: selectedField.city != null ? this.contactJobRecords[i][selectedField.city] : null,
              Region: selectedField.region != null ? this.contactJobRecords[i][selectedField.region] : null,
              PostalCode: selectedField.postal != null ? this.contactJobRecords[i][selectedField.postal] : null,
              Country: selectedField.country != null ? this.contactJobRecords[i][selectedField.country] : null,
              CompanyName: selectedField.companyName != null ? this.contactJobRecords[i][selectedField.companyName] : null,
              CompanyAddress1: selectedField.companyAddress1 != null ? this.contactJobRecords[i][selectedField.companyAddress1] : null,
              CompanyAddress2: selectedField.companyAddress2 != null ? this.contactJobRecords[i][selectedField.companyAddress2] : null,
              CompanyCity: selectedField.companyCity != null ? this.contactJobRecords[i][selectedField.companyCity] : null,
              CompanyRegion: selectedField.companyRegion != null ? this.contactJobRecords[i][selectedField.companyRegion] : null,
              CompanyPostal: selectedField.companyPostal != null ? this.contactJobRecords[i][selectedField.companyPostal] : null,
              CompanyCountry: selectedField.companyCountry != null ? this.contactJobRecords[i][selectedField.companyCountry] : null,
              CompanyPhone: selectedField.companyPhone != null ? this.contactJobRecords[i][selectedField.companyPhone] : null,
              CompanyWebsite: selectedField.companyWebsite != null ? this.contactJobRecords[i][selectedField.companyWebsite] : null,
              Internal_Record_Id: selectedField.id != null ? this.contactJobRecords[i][selectedField.id] : null,
            };
            this.importContactJobRecords.push(obj);
          }
          addJobData = {
            JobName: this.jobTitle,
            LastBatch: lastBatch,
            DataEnrichmentJob_Id: null,
            ContactJobRecords: this.importContactJobRecords,
            ContactUserDefinedTags: null,
            ContactSavedFrom: "enrichment",
            IsExport: true,
          };
          allAddJobData.push(addJobData);
        }
        // post data using axios
        let batch = 0;
        this.postAllRecords(allAddJobData, batch);
      }

      const element = document.getElementById("backToTop");
      element.scrollIntoView();
    },
    // Post records using axios
    async postAllRecords(allAddJobData, batch) {
      if (batch == 0) {
        allAddJobData[batch].DataEnrichmentJob_Id = 0;
      }
      if (batch < allAddJobData.length) {
        this.$vs.loading();
        await this.axios.post("/ws/DataEnrichmentAddJob/RunDataEnrichmentJob",allAddJobData[batch])
          .then((response) => {
            if (response.data.DataEnrichmentJob_Id!=null) {
              this.dataEnrichmentJob_Id = response.data.DataEnrichmentJob_Id;
            }
            if(response.data.UploadCompleted== true && this.UploadMessage1!="duplicate found"){
              this.addDataenrichmentPopup = false;
              batch++;
              if (batch < allAddJobData.length) {
                allAddJobData[batch].DataEnrichmentJob_Id = this.dataEnrichmentJob_Id;
                this.postAllRecords(allAddJobData, batch);
              }
              if ( batch >= allAddJobData.length ) {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Success",
                  text: "Data Enrichment job has been added successfully.",
                  color: "success",
                  iconPack: "feather",
                  icon: "icon-check",
                  position: "top-right",
                  time: 4000,
                });
                this.getDataEnrichmentJobs();
                this.addJobSubmittedPopup=true
                this.closeImportCvsPopup();
              }
            }else if(response.data.UploadCompleted== false && response.data.UploadMessage=="duplicate found"){
              this.showJobTitleError=true
              document.getElementById("jobTitleId").focus();
              this.$vs.loading.close();
            }
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          });
      }
    },
    //Close Import Contacts from CSV File popup and clear all fields
    closeImportCvsPopup() {
      this.addDataenrichmentPopup = false;
      this.importContactFileError = null;
      this.jobTitle = null;
      this.showJobTitleError=false

      this.clearData();

      const element = document.getElementById("backToTop");
      element.scrollIntoView();

      setTimeout(() => {
        this.errors.clear("addJobForm");
      }, 20);
    },
    // get download limit
    async GetContactLimits() {
      await this.axios.get("/ws/PluginFindAnyoneSearchContacts/GetContactLimits")
        .then((response) => {
          let data = response.data;
          this.totalContactLimitRemaining = data.TotalContactLimitRemaining;
        });
    },
  },
  filters: {
    JobCreatedDateFormat: function (value) {
      return moment(value).format("MMMM DD, YYYY hh:mm a");
    },
  },
};
</script>