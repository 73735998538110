<template>
  <vs-popup class="alert-popup" :active.sync="isStatusPopup">
    <template>
      <div class="vx-row mb-3">
        <div class="vx-col w-full text-center">
          <div class="popup-icon data-status-icon">
            <span
              class="material-icons"
              v-if="jobStatusIcon == 'error' || jobStatusIcon == 'timelapse'"
              >{{ jobStatusIcon }}</span
            >
            <feather-icon :icon="jobStatusIcon" v-else></feather-icon>
          </div>
          <h2 class="text-primary py-3">{{ jobStatusMessage }}</h2>
        </div>
      </div>
    </template>
    <div class="text-center mt-6 mb-8">
      <vs-button
        :title="LabelConstant.buttonLabelOkay"
        color="primary"
        type="filled"
        class="mr-2"
        @click="$emit('clickForFinish')"
        >{{ LabelConstant.buttonLabelOkay }}</vs-button
      >
    </div>
  </vs-popup>
</template>

<script>
export default {
  props: {
    isStatusPopup: {
      type: Boolean,
      required: true,
      default: false,
    },
    jobStatusIcon: {
      type: String,
      required: true,
    },
    jobStatusMessage: {
      type: String,
      required: true,
    },
  },
};
</script>
